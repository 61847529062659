/**
 * Load a package from cdn.jsdelivr.net
 */
export function jsDelivr<PackageExports>(
  pkg: string,
  version: string,
  path?: string
): Promise<PackageExports> {
  const url = `https://cdn.jsdelivr.net/npm/${pkg}@${version}${path}`;
  if (path && !path.startsWith('/')) {
    throw new Error(
      `jsDelivr ${pkg}: If a path is used, it must start with "/"`
    );
  }
  return import(/* webpackIgnore: true */ url);
}
