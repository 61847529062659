import { HiddenText } from '@codecademy/gamut';
import styled from '@emotion/styled';
import { use40Phaser } from '@mono/util-40phaser';

const Area = styled.div`
  display: flex;
  justify-content: center;
  margin: 6rem 1rem;

  canvas {
    max-width: 100vw;
  }
`;

export const Portal404Page: React.FC = () => {
  const parentRef = use40Phaser<HTMLDivElement>();

  return (
    <main data-testid="error404">
      <HiddenText>404: Page Not Found</HiddenText>
      <Area aria-label="This page doesn't exist." ref={parentRef} />
    </main>
  );
};
