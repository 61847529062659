import { Box, Text } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import React from 'react';

import notFound from './404.svg';

interface NotFoundPageProps {
  title?: string;
  subtitle?: string;
  hiddenText?: string;
}

const ErrorContainer = styled(Box)(
  css({
    canvas: {
      maxWidth: '100vw',
    },
  })
);

const Image = Box.withComponent('img');

export const NotFoundContent: React.FC<NotFoundPageProps> = ({
  title = 'Oh no!',
  subtitle = "Looks like you're lost.",
  hiddenText = '404: Page Not Found',
}) => {
  return (
    <main data-testid="error404">
      <Text screenreader>{hiddenText}</Text>
      <ErrorContainer
        as="main"
        my={96}
        mx={16}
        textAlign="center"
        data-testid="error404"
      >
        <Image src={notFound} alt="" height="125" width="500" maxWidth="100%" />
        <Box my={48}>
          <Text as="h1" variant="title-xl">
            {title}
          </Text>
          <Text as="h2" variant="title-lg">
            {subtitle}
          </Text>
        </Box>
      </ErrorContainer>
    </main>
  );
};
