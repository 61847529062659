import { useEffect, useRef } from 'react';

import { jsDelivr } from './jsDelivr';
import { _40Phaser, _40PhaserOptions } from './types';

/*
 * 40Phaser is a game that we show on 404 pages.
 *
 * We were previously loading phaser and 40phaser via import('phaser') and
 * import('40phaser'). With that approach, webpack would convert the imports so
 * that they resolved chunks included in our own bundle. Profiling portal-app
 * in staging revealed that about 9% of node process memory was used by the
 * string containing the phaser bundle chunk, which was held in memory to be
 * served as needed. The size of that string is not entirely surprising since
 * phaser is a large game framework. Thus, we've opted to load both libraries
 * from cdn.jsdelivr.net instead.
 */

export type Use40PhaserOptions = Pick<_40PhaserOptions, 'on'>;

/**
 * Load phaser and 40Phaser from cdn.jsdelivr.net and launch the game.
 *
 * @returns A ref to be attached to the parent element that will contain the game.
 */
export function use40Phaser<ParentElementType>(
  options: Use40PhaserOptions = {}
) {
  const parentRef = useRef<ParentElementType>(null);
  const init = useRef(false);
  useEffect(() => {
    if (init.current) return; // prevent double init
    init.current = true;
    loadAndLaunch(options, parentRef.current as HTMLElement);
  }, [options]);
  return parentRef;
}

async function loadAndLaunch(options: Use40PhaserOptions, parent: HTMLElement) {
  const [phaser, { launch40Phaser }] = await Promise.all([
    jsDelivr<unknown>('phaser', '3.88.2', '/dist/phaser.esm.min.js'),
    jsDelivr<_40Phaser>('40phaser', '1.1.1', '/src/index.js'),
  ]);
  launch40Phaser({
    ...options,
    phaser,
    phaserOptions: { parent },
  });
}
