import { NotFoundContent } from '@mono/brand';
import { useUser } from '@mono/data-user';

import { Portal404Page } from '~/components/Portal404Page';
import { PortalPage } from '~/components/PortalPage';

import { useEnterpriseUser } from '../libs/useEnterpriseUser';

export default function NotFound() {
  const user = useUser();
  const enterpriseUser = useEnterpriseUser(user);

  return (
    <PortalPage
      meta={{
        description: 'Something has gone wrong.',
        title: 'Page Not Found',
      }}
      tracking={{
        page_name: 'error',
      }}
    >
      {enterpriseUser ? <NotFoundContent /> : <Portal404Page />}
    </PortalPage>
  );
}
